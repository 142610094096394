import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [ "image", "overlay" ];

    // Creates a duplicate of the original image, and makes it larger.
    // When the duplicate is clicked, it is removed.
    zoomIn(event) {
        event.stopPropagation();
        const originalImage = this.imageTarget;
        const overlay = this.createOverlay();
        const duplicateImage = this.createDuplicateImage(originalImage);

        document.body.appendChild(overlay);
        document.body.appendChild(duplicateImage);
        this.animateZoomIn(duplicateImage);

        const zoomOut = () => {
            this.animateZoomOut(originalImage, duplicateImage);
            overlay.remove();
            document.removeEventListener("click", zoomOut);
        }

        document.addEventListener("click", zoomOut);
        duplicateImage.addEventListener("click", zoomOut);
    }

    createDuplicateImage(originalImage) {
        const duplicateImage = originalImage.cloneNode(true);
        duplicateImage.classList.add("zoomed");
        duplicateImage.style.position = "absolute";
        duplicateImage.style.left = `${originalImage.offsetLeft}px`;
        duplicateImage.style.top = `${originalImage.offsetTop}px`;
        duplicateImage.style.width = `${originalImage.offsetWidth}px`;
        duplicateImage.style.height = `${originalImage.offsetHeight}px`;
        return duplicateImage;
    }

    createOverlay() {
        const overlay = document.createElement("div");
        overlay.classList.add("image-overlay", "active");
        return overlay;
    }

    animateZoomIn(duplicateImage) {
        setTimeout(() => {
            duplicateImage.style.transform = "translate(-50%, -50%) scale(2)";
            duplicateImage.style.left = "50%";
            duplicateImage.style.top = "50%";
        }, 0);
    }

    animateZoomOut(originalImage, duplicateImage) {
        duplicateImage.style.transform = "translate(-50%, -50%) scale(0)";
        duplicateImage.style.left = `${originalImage.offsetLeft}px`;
        duplicateImage.style.bottom = `-${originalImage.offsetTop}px`;
        setTimeout(() => {
            duplicateImage.remove();
        }, 500);
    }
}
